@import './shared/variables.scss';

html {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: $defaultFont;
  height: 100%;
}

#root {
  height: 100%;
}
