@import '../../../shared/variables.scss';
@import '../../../shared/mixins.scss';

.container {
  @include display_flex;
  @include flex_direction(column);
}

.labelText {
  @include label_text;
  margin-bottom: 5px;
}

.descriptionText {
  @include regular_text;
  margin-bottom: 30px;
}

.searchContainer {
  @include display_flex;
  @include flex_direction(row);
  @include flex_justify(flex-start);
  margin-bottom: 40px;
}

.formInput {
  @include display_flex;
  width: 250px;
  margin-right: 40px;
}

.searchButton {
  @include display_flex;
  width: 140px;
  margin-right: 20px;
}

.clearButton {
  cursor: pointer;
}

.switchInput {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  margin-bottom: 40px;
}

.orDiv {
  @include display_flex;
  @include flex_align_items(center);
  @include flex_justify(center);
  @include regular_text;
  margin: auto 20px 5px -20px;
  height: 100%;
}

/* Hide default HTML checkbox */
.switchInput input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primaryGrey;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 4px;
    background-color: $primaryWhite;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

input:checked + .slider {
  background-color: $secondaryGreen;
}

input:focus + .slider {
  box-shadow: 0 0 1px $secondaryGreen;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

@media (max-width: 1024px) {
  .formInput {
    margin-right: 10px;
  }

  .searchContainer {
    @include flex_direction(column);
  }

  .formInput {
    width: 100%;
    margin-bottom: 10px;
  }

  .orDiv {
    display: none;
  }
}
