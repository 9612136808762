@import '../../../shared/variables.scss';
@import '../../../shared/mixins.scss';

.container {
  word-wrap: break-word;
}

.text > span {
  margin-right: 3px;
}

.text:after {
  content: '';
  display: inline-block;
  background: url('/assets/icon_disambiguated.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-bottom: 3px;
}

.delayWrap {
  white-space: nowrap;
}

.indicator::after {
  content: "*";
  color: $primaryRed;
  margin-left: 5px;
  font-weight: 600;
  font-size: 18px;
}

/* IE style */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container {
    word-break: break-all;
  }

  .delayWrap {
    white-space: normal;
  }
}

@media (max-width: 1050px) {
  .delayWrap {
    white-space: normal;
  }
}
