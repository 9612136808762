// Colors
$primaryRed: #e20026;
$primaryBlack: #1d1d1d;
$primaryGrey: #999999;
$primaryWhite: #fff;
$defaultFont: #333333;
$secondaryGreen: #008f59;
$secondaryBlue: #3b71d8;
$secondaryOrange: #ff9800;
$lightShade: #a3001b;
$darkShade: #630011;
$greyBorder: #dadada;
$greyBackground: #f7f7f7;
$headerBorder: #4b4b4b;
$successAlert: #008f59;
$errorAlert: #ff7060;

// font sizes
$fs_regular: 13px;
$fs_heading1: 26px;
$fs_heading2: 21px;
$fs_heading3: 18px;

//font-weights
$fw_regular: 400;
$fw_semiBold: 600;
$fw_bold: 700;
