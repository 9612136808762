@import '../../../shared/variables.scss';
@import '../../../shared/mixins.scss';

.container {
  color: $defaultFont;
  @include regular_text;
}

.reportTypeSelectDiv {
  @include display_flex;
  @include flex_direction(row);
  width: 100%;
  margin-bottom: 25px;
}

.actionButton {
  @include display_flex;
  min-width: 180px;
  margin-right: 10px;
}

.alertMessageDiv {
  margin-bottom: 25px;
}
