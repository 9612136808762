@import '../../../shared/variables.scss';
@import '../../../shared/mixins.scss';

.container {
  color: $defaultFont;
}

.text {
  font-size: $fs_regular;
  margin-bottom: 1.5rem;
}
