@import '../../shared/variables.scss';
@import '../../shared/mixins.scss';

.switchContainer {
  @include display_flex;
  @include flex_direction(row);
  @include flex(0.5);
}

.switchContainerTextTop {
  @include flex_direction(column);
}

.switchInput {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  margin: 0 0 25px 25px;
}

.switchInputLeft {
  margin: 10px 0 0 0;
}

/* Hide default HTML checkbox */
.switchInput input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primaryGrey;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 4px;
    background-color: $primaryWhite;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

input:checked + .slider {
  background-color: $secondaryGreen;
}

input:focus + .slider {
  box-shadow: 0 0 1px $secondaryGreen;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
