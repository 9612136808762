@import '../../../shared/variables.scss';
@import '../../../shared/mixins.scss';

.container {
  @include display_flex;
  @include flex_direction(column);
}

.row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 7px;
}

@media (max-width: 1200px) {
  .container {
    max-width: 150px;
  }
}

@media (max-width: 1150px) {
  .container {
    max-width: 125px;
  }
}

@media (max-width: 1100px) {
  .container {
    max-width: 100px;
  }
}

@media (max-width: 1080px) {
  .container {
    max-width: 80px;
  }
}
