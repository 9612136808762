@import '../../../shared/variables.scss';
@import '../../../shared/mixins.scss';

.input {
  width: 100%;
  height: 30px;
  color: $defaultFont;
  border: 1px solid $greyBorder;
  @include regular_text();
  outline: none;

  &:focus {
    border: 1px solid $primaryBlack;
  }
}
